// Set site info
var siteinfo = document.getElementsByName("siteinfo")[0];
var site_id = siteinfo.getAttribute("data-siteid");
var lang = siteinfo.getAttribute("data-sitelang");



$(document).ready(function ()
{
	$('#nav-open').click(function(e){
		e.preventDefault();
	
		$('body').addClass('is-nav-open');
	});
	
	$('#nav-close').click(function(e){
		e.preventDefault();
	
		$('body').removeClass('is-nav-open');
	});

	$('#residence-slider').slick({
		dots: true,
		arrows: true,
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"></button>',
		nextArrow: '<button type="button" class="slick-next"></button>',
	});

	$('#photo-slider').slick({
		slidesToShow: 4, 
		prevArrow: '<button type="button" class="slick-prev"></button>',
		nextArrow: '<button type="button" class="slick-next"></button>',
		responsive: [{
			breakpoint: 768,
			settings: {
				slidesToShow: 3
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2
			}
		}]
	});

	
	$('a[data-module]').click(function() {
		const $button = $(this);
		const module = $button.attr('data-module');
		
		const $select = $('select#module');
		$select.val(module);

		$('html,body').animate({
			scrollTop: $('.section-module-form').offset().top - 30
		}, 300);
	});


	/*
	 *	RICH-CONTENT
	 */

	// Lightbox
	$('.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		buttons: ['close']
	});
	$('.lightbox-media').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		helpers : {
			media : {}
		}
	});


	// Youtube/Vimeo thumbs
	$(".rc-video__thumb").not('.lightbox-media').click( startVideo );
	$(".rc-video__overlay").not('.lightbox-media').click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('data-src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			if( src.indexOf('?') > -1 )
			{
				iframe.attr('src', src + '&autoplay=1');
			}
			else
			{
				iframe.attr('src', src + '?autoplay=1');
			}
		}

		$('.rc-video__overlay', block.parent()).fadeOut(150);
		$('.rc-video__thumb', block.parent()).fadeOut(150);
	}

	// Contentblocks slider
	$('.rc-article-slider').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// Quote slider
	$('.rc-quote-slider__slick').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// File upload
	bsCustomFileInput.init()
});

function noResultsCheck(event, laidOutItems) {
	var numItems = laidOutItems.length;

	if (numItems == 0) {
		$('.grid-title').hide();
		$('#grid-no-results').show();
	} else {
		$('.grid-title').show();
		$('#grid-no-results').hide();
	}
}

$(window).on('load', function ()
{
	// Image slider (options in data-slick)
	$('.rc-image-slider__slick').slick();

	/*
	 *	FILTER SLIDER
	 */

	$filter_slider = $('#filter-slider').slick({
		fade: true,
		adaptiveHeight: true,
		arrows: false
	});

	

	/*
	 *	GRID + FILTER
	 */

	/*
	var $grid = $('#grid-residences');

	$grid.on('layoutComplete', noResultsCheck);

	$grid.isotope({
		itemSelector: '.residence-card',
		percentPosition: true,
		masonry: {
			columnWidth: '.residence-card',
			gutter: 0
		},
	});
	*/


	function filterChanged(e) {
		var cat = $('#filter-category option:selected').attr('value');
		var province = $('#filter-province option:selected').attr('value');
		var wellness = $('#filter-wellness option:selected').attr('value');
		var room = $('#filter-room option:selected').attr('value');

		if (cat  && cat != -1) {
			$('.itemslist').attr('data-linked', cat);
		}else{
			$('.itemslist').attr('data-linked', null);
		}
		
		var filter = {};
		if (province  && province != -1) {
			filter.field_province = province;
		}
		if (wellness  && wellness != -1) {
			filter.field_wellness = wellness;
		}
		if (room  && room != -1) {
			filter.field_room = room;
		}

		$('.itemslist').attr('data-filter', JSON.stringify(filter));
		
		var pagenr = 1;
		fetch_itemslist(pagenr);
	}

	if ($('.itemslist').length) {
		filterChanged();
	}
  
	$('#filter-province').change(filterChanged);
	$('#filter-category').change(filterChanged);
	$('#filter-wellness').change(filterChanged);
	$('#filter-room').change(filterChanged);
});







// CAPTCHA
var captcha_validated = false;

function onSubmitreCAPTCHA(response)
{
	captcha_validated = true;
	$('.rc-form form').submit();
}

function validateForm(event)
{
	event.preventDefault();
	var form = this;

	var validator = $(form).data('bs.validator');
	validator.validate();

	if (captcha_validated && !validator.hasErrors()) {
		form.submit();
	} else if(!captcha_validated) {
		grecaptcha.reset();
		grecaptcha.execute();
	}
}
